import React, { useState } from "react";
import { Fade } from "react-reveal";
import { HashLink as Link } from "react-router-hash-link";
import { FaWhatsapp } from "react-icons/fa";

const PricePlan = () => {
  const [priceToggle, setPriceToggle] = useState(true);
  const handleWhatsAppClick = () => {
    const phoneNumber = "+972544311942";
    const textMessage = encodeURIComponent(
      "היי,הייתי באתר שלכם.אשמח לשמוע פרטים 😊"
    );
    const whatsappUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${textMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <section className="sc-price-area gray-bg sc-pt-125 sc-md-pt-65 sc-pb-140 sc-md-pb-80">
      <div className="container">
        <div className="sc-heading-area sc-mb-40 sc-md-mb-30 text-center">
          <h2 className="title sc-mb-25">התוכניות שלנו</h2>
        </div>

        <div
          className={`${
            priceToggle ? "pricing-monthly" : "pricing-monthly d-none"
          }`}
        >
          <div className="row">
            <Fade bottom delay={100}>
              <div
                className="col-lg-3 col-md-6 sc-md-mb-30"
                style={{ textAlign: "center" }}
              >
                <div
                  className="sc-price-box"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <div className="price-item">
                    <h4 className="pric-title">Maximum</h4>
                    <div className="price-active">
                      <span
                        style={{
                          marginRight: "5px",
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        תלוי בעסק
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "15px",
                      color: "purple",
                      fontWeight: "bold",
                    }}
                  >
                    חבילה מיוחדת לעסקים גדולים
                  </div>
                  <div className="price-list">
                    <ul className="list" style={{ textAlign: "center" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          כמות עובדים גדולה יותר
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          כמות משתמשים גדולה יותר
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          כמות סמסים גדולה יותר
                        </span>
                        <hr />
                      </li>
                    </ul>
                  </div>

                  <div>
                    <button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "15px",
                        fontWeight: "bold",
                        display: "flex",
                        width: "100%",
                      }}
                      onClick={handleWhatsAppClick}
                    >
                      <FaWhatsapp style={{ marginRight: "5px" }} />
                      צרו קשר
                    </button>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={200}>
              <div
                className="col-lg-3 col-md-6 sc-md-mb-30"
                style={{ textAlign: "center" }}
              >
                <div
                  className="sc-price-box"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <div className="price-item">
                    <h4 className="pric-title">ultimate</h4>
                    <div className="price-active">
                      <span
                        style={{
                          marginRight: "5px",
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        ₪399
                      </span>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "gray",
                          fontWeight: "bold",
                          marginTop: "-25px",
                        }}
                      >
                        לחודש
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "18px",
                      color: "purple",
                      fontWeight: "bold",
                    }}
                  >
                    חבילה מורחבת
                  </div>
                  <hr />
                  <div className="price-list">
                    <ul className="list" style={{ textAlign: "center" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          יומן דיגיטלי
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          קופה מלאה כולל סליקה
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          ניהול רכש ומלאי
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          שוברי גיפט-קארד
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          שימוש במערכת גם מהנייד
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          עד 7 מטפלים
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          עד 5 עובדים
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          עד 2 משתמשים
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          תזכורות סמס
                        </span>
                        <hr />
                      </li>
                      <span style={{ fontSize: "12px", color: "gray" }}>
                        זימון תורים ללקוח כלול בחבילה
                        <br />
                        כל מטפל או עובד נוסף ₪19.90
                        <br />
                        כלול WhatsApp חיבור אוטומציות
                        <FaWhatsapp
                          size={15}
                          color="green"
                          style={{ marginLeft: "5px" }}
                        />
                      </span>
                      <hr />
                      המחירים אינם כוללים מע״מ
                      <hr />
                    </ul>
                  </div>

                  <div>
                    <button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "15px",
                        fontWeight: "bold",
                        display: "flex",
                        width: "100%",
                      }}
                      onClick={handleWhatsAppClick}
                    >
                      <FaWhatsapp style={{ marginRight: "5px" }} />
                      צרו קשר
                    </button>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={300}>
              <div
                className="col-lg-3 col-md-6 sc-md-mb-30"
                style={{ textAlign: "center", position: "relative" }}
              >
                <div
                  className="ribbon"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "0px",
                    backgroundColor: "dodgerblue",
                    color: "#fff",
                    padding: "5px 15px",
                    transform: "rotate(-45deg)",
                    zIndex: "1",
                    fontWeight: "bold",
                    borderRadius: "5px",
                  }}
                >
                  פופולרי
                </div>
                <div
                  className="sc-price-box"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <div className="price-item">
                    <h4 className="pric-title">pro</h4>
                    <div className="price-active">
                      <span
                        style={{
                          marginRight: "5px",
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        ₪329
                      </span>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "gray",
                          fontWeight: "bold",
                          marginTop: "-25px",
                        }}
                      >
                        לחודש
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "18px",
                      color: "purple",
                      fontWeight: "bold",
                    }}
                  >
                    חבילה מקצועית
                  </div>
                  <hr />
                  <div className="price-list">
                    <ul className="list" style={{ textAlign: "center" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          יומן דיגיטלי
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          קופה מלאה כולל סליקה
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          ניהול רכש ומלאי
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          שוברי גיפט-קארד
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          שימוש במערכת גם מהנייד
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          עד 5 מטפלים
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          עד 3 עובדים
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          עד 2 משתמשים
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          תזכורות סמס
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "12px", color: "gray" }}>
                          זימון תורים ללקוח ₪29.90
                          <br />
                          כל מטפל או עובד נוסף ₪19.90
                          <br />
                          ₪29.90 WhatsApp חיבור אוטומציות
                          <FaWhatsapp
                            size={15}
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </span>
                        <hr />
                        המחירים אינם כוללים מע״מ
                      </li>
                    </ul>
                  </div>
                  <div>
                    <button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "15px",
                        fontWeight: "bold",
                        display: "flex",
                        width: "100%",
                      }}
                      onClick={handleWhatsAppClick}
                    >
                      <FaWhatsapp style={{ marginRight: "5px" }} />
                      צרו קשר
                    </button>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom delay={400}>
              <div
                className="col-lg-3 col-md-6 sc-md-mb-30"
                style={{ textAlign: "center" }}
              >
                <div
                  className="sc-price-box"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <div className="price-item">
                    <h4 className="pric-title">Basic</h4>
                    <div className="price-active">
                      <span
                        style={{
                          marginRight: "5px",
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        ₪209
                      </span>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "gray",
                          fontWeight: "bold",
                          marginTop: "-25px",
                        }}
                      >
                        לחודש
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "18px",
                      color: "purple",
                      fontWeight: "bold",
                    }}
                  >
                    חבילה בסיסית
                  </div>
                  <hr />
                  <div className="price-list">
                    <ul className="list" style={{ textAlign: "center" }}>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          יומן דיגיטלי
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          שימוש במערכת גם מהנייד
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          עובד 1
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          עד 2 מטפלים
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          עד 2 משתמשים
                        </span>
                        <hr />
                      </li>
                      <li>
                        <span style={{ fontSize: "15px", color: "gray" }}>
                          תזכורות סמס
                        </span>
                        <hr />
                      </li>
                      <span style={{ fontSize: "12px", color: "gray" }}>
                        זימון תורים ללקוח ₪29.90
                        <br />
                        כל מטפל או עובד נוסף ₪19.90
                        <br />
                        ₪29.90 WhatsApp חיבור אוטומציות
                        <FaWhatsapp
                          size={15}
                          color="green"
                          style={{ marginLeft: "5px" }}
                        />
                      </span>
                      <hr />
                      המחירים אינם כוללים מע״מ
                    </ul>
                  </div>

                  <div>
                    <button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "15px",
                        fontWeight: "bold",
                        display: "flex",
                        width: "100%",
                      }}
                      onClick={handleWhatsAppClick}
                    >
                      <FaWhatsapp style={{ marginRight: "5px" }} />
                      צרו קשר
                    </button>
                  </div>
                </div>
              </div>
            </Fade>
            <div
              style={{
                backgroundColor: "white",
                color: "gray",
                padding: "10px",
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              תמיכה כלולה בכל החבילות ללא עלות נוספת ✔️ללא הגבלה ללא חוזים, בטל
              בכל זמן✔️
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricePlan;
